.plugin_contrast_button {
    cursor: pointer;
}

.page_contrast {
    .menu_btn > div {
        background-color:yellow!important;
    }

    .ico {
        filter:brightness(10);
    }
}

.contrast{
    button{
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        transition: 0.3s all ease;
        &:hover{
            background-color: #012880;
        }
    }
}


.plugin_font_sizes {
    white-space:nowrap;

    > li {
        display: inline-block;
        vertical-align: top;
        text-transform: uppercase;
        margin-right: 5px;
        button{
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 50%;
            transition: 0.3s all ease;
            &:hover{
                background-color: #012880;
            }
        }
        //a {
        //    cursor: pointer;
        //}

        &:last-child{
            margin-right: 0;
        }
        //&.font_large{
        //    font-size: 40px;
        //    line-height: 40px;
        //}
        //&.font_normal{
        //    font-size: 30px;
        //    line-height: 30px;
        //    padding-top: 7px;
        //}
        //&.font_small{
        //    font-size: 20px;
        //    line-height: 20px;
        //    padding-top: 14px;
        //}
    }
}
